import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Financial education is about as alluring as flossing your teeth.”`}</p>
    <p>{`That’s how I was introduced at a panel of financial inclusion startups at `}<a parentName="p" {...{
        "href": "http://socap14.socialcapitalmarkets.net/"
      }}>{`SOCAP14`}</a>{` earlier this week in San Francisco. `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/pub/kat-taylor/4b/697/28a"
      }}>{`Kat Taylor`}</a>{`, the panel moderator and CEO of the very awesome `}<a parentName="p" {...{
        "href": "http://beneficialstatebank.com/"
      }}>{`Beneficial State Bank`}</a>{`, was clearly trying to spice things up by starting off with that accusation. But I didn’t disagree.`}</p>
    <p>{`However, I responded, there is one huge difference between financial education and flossing. Flossing works.`}</p>
    {
      /*more*/
    }
    <p>{`It’s no joke. Almost all the research proves that standard financial education does not improve financial health. Sit someone down in a class for 6 hours and teach them about budgeting, saving, mortgages, etc. They might learn a thing or two, but the likelihood that they will change any of their financial behaviors is virtually zero.`}</p>
    <p>{`PayPerks buys into a totally different model of financial education, one called `}<strong parentName="p"><em parentName="strong">{`financial capability`}</em></strong>{`. Financial capability is focused on changing financial behaviors for the better, not just increasing basic knowledge. According to our friends at `}<a parentName="p" {...{
        "href": "http://www.cfsinnovation.com/publications/article/440486"
      }}>{`CFSI`}</a>{`, for a financial capability product or tool to actually change behavior, it needs to have these four traits:`}</p>
    <ul>
      <li parentName="ul">{`Timely`}</li>
      <li parentName="ul">{`Relevant`}</li>
      <li parentName="ul">{`Actionable`}</li>
      <li parentName="ul">{`Ongoing`}</li>
    </ul>
    <p>{`The PayPerks platform has all four.`}</p>
    <p>{`We teach our users how to maximize their prepaid cards at `}<em parentName="p">{`timely`}</em>{` moments, like when they get a brand new card in the mail. We’ve designed fun, illustrated, bilingual, `}<em parentName="p">{`relevant`}</em>{` content that delivers `}<em parentName="p">{`actionable`}</em>{` ideas that our users can to put work immediately with the prepaid card in their hands. And our `}<em parentName="p">{`ongoing`}</em>{` monthly sweepstakes keep users coming back month after month to win prizes and increase their financial capability.`}</p>
    <p>{`So yes, traditional financial education is about as exciting as your basic oral hygiene without any of the benefits. But financial capability, especially when it’s done PayPerks-style, is like an electronic toothbrush that plays your favorite song, leaving you with perfect teeth and a smile. :-)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.25000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADl0lEQVQ4yz2N20+bBQBH+ycMENZxZ1g67hQmt4KwsnJfB6MwRuWijDEmIBfZCMNxmwYGYyBIpbQFDHMCS8ThBk7o147JrXQQF0w0JqZPPhgXoz5/xzCIDyf5/V7Okbx8NsWeYGZXMPHif8w4BBPbFiN2wYRdmGTbamZbMLFlMbKxamRTMLFpMbGxeoBR3BDu8+tvPzsl+2vT/LJ5n/3n0+zaTOwdYDWzZ5tk79kkO1YTDpuZHZv5tXzHeri3rKbDiG2SbduUaH8+x19/v3JKflozM2/oYHW+n5drZnatRvYOBJYJ1r/T47AZsVsNOI7Ea0tjWB6PYreZjphka8UgOtaPhPuCnsH3tcwO1PGjbZytpWFeCOOsfNlDX20hy/N92FfH2Pz+Mxy2CaZ6amiruciG5XO2rBMIC4P0NevEGcMn/PHn707JzqM7DNQV0VVXzDdTN1n7+jb2p0PMDTdxvTidp3Mfs7E0wPryPeyCnpG29+i6UsCTh/1sC3pWHvbRVJovDvV/xD//vnJKGkrTKclUkpX0FjqNkuWZVhb0jQw3FDF28zKtV86zYG5h7ds+Ru/U015bzlWdhpzMFBYf9DB2t4FCjVos0V1ic2fbKclMjECbEUttRQ6as7H03ihCkxZPd52ORX0baXGRjHSX85XhAyouqpk1fIgmI4mk+CimRpupKtOQlBgnqtPTWXzy2Cnp1Klo153hh/k2uq9pGWy/hDYrhtH2Snob3yEqVE5ns5bCHCVlBSo2H7VTnq8iOSGahZlbmPvr0KgTxdTUFMyGIadkqj6bztxoWnJiaCpKprUqgzx1DDHhMny9TyAP9KO+MovKkrP4+niTHh1MSlQQOcoolu/VMHKrlPqqbLGyNBfDyG2npDBFwQVlKEnhgaTFyFHHBRMq80cZG44yNgxVsoLi/FSuVeSgSo5GdtIfbZ6Kjuo8+t/NpfWqlo4bZeLdnmq+GO91SsJlATQWJHP5XBzq2GDOxEWgCHkTRZiMkCA/gmW+BPhK8fGSknA6/HWorakM070GmsvOMf1pI7MT18UH4y30dzU4Jcc9PEiQ+1GslJOZFM3pyDDC5QFI3d1xc3XFzcWVQD8PfL2keEqlhMgDiAiVc14dT2VuMk3VBVzIfluMCjtF6CmZU+LxhjuR/ifIDvUkXXEST+lxXI654OpyyIHw2NF3d3M9jLi5EujjRVp0EFkpChIUIaKftycB/r7O/wBg2WA7dsC2ngAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/7f61c/rob-levy-at-SOCAP14-panel.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/d00b9/rob-levy-at-SOCAP14-panel.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/8bcb7/rob-levy-at-SOCAP14-panel.webp 899w"],
            "sizes": "(max-width: 899px) 100vw, 899px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/e17e5/rob-levy-at-SOCAP14-panel.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/5a190/rob-levy-at-SOCAP14-panel.png 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/681f1/rob-levy-at-SOCAP14-panel.png 899w"],
            "sizes": "(max-width: 899px) 100vw, 899px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/a540ddd1c67230be0cb71a190e27da3d/681f1/rob-levy-at-SOCAP14-panel.png",
            "alt": "rob levy at SOCAP14 panel",
            "title": "rob levy at SOCAP14 panel",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><em parentName="p">{`Rob Levy (`}<a parentName="em" {...{
          "href": "https://twitter.com/robdlevy"
        }}>{`@robdlevy`}</a>{`) is PayPerks Director of Partner Development and Consumer Impact.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      